<template>
  <div>
    <b-card class="w-100 py-2 px-4 mb-2" no-body :border-variant="selected_id === template.id ? 'primary' : ''">
      <section class="d-md-flex align-items-center justify-content-between">
        <cards-list grid="4" class="w-100">
          <div cv-if="template.indicator">
            <strong class="d-block">Indicador: </strong>
            <span>{{ template.indicator.name }}</span>
          </div>

          <div style="max-width: 150px; white-space: normal; word-wrap: break-word;">
            <strong class="d-block">Fonte de pesquisa: </strong>
            <span v-if="template && template.indicator">
              {{ template.indicator.argument.name }}
            </span>
          </div>

          <div>
            <strong class="d-block">Validação: </strong>
            <span>{{ getTypeValidation(template.indicator.type) }} </span>
          </div>
          <div>
            <strong class="d-block">Peso: </strong>
            <span>{{ template.weight }} </span>
          </div>
        </cards-list>

        <div class="icon-action d-flex  justify-content-end" v-if="!is_provider">
          <b-button variant="light p-2 mr-1" size="sm" @click="$emit('edit')">
            <b-icon v-b-popover.hover.top="'Editar'" class="h6 pointer mb-0" icon="pen" />
          </b-button>
          <b-button variant="light p-2" size="sm" @click="$emit('delete')">
            <b-spinner v-if="selected_id === template.id" type="grow" small class="h6 mb-0" />
            <b-icon v-else v-b-popover.hover.top="'Excluir'" class="h6 pointer mb-0" icon="trash" />
          </b-button>
          <b-button v-b-popover.hover.top="'Vizualizar faixas'" variant="light p-2" size="sm" v-if="template.id"
            v-b-toggle="[`template-${index}`]">
            <b-icon class="h6 mb-0" icon="eye" />
          </b-button>
        </div>
      </section>
      <b-collapse :id="`template-${index}`" class="mb-4 mt-3">
        <div>
          <strong>Pontuação</strong>
          <Ranges class="mt-2" :type="template.indicator.type" ready :tracks="template.ranges"
            :minValue="template.minValue" :interval="template.interval" />
        </div>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Ranges from "./Ranges";

export default {
  components: {
    Ranges,
  },
  props: {
    template: {
      type: Object,
    },
    index: {
      type: Number,
    },
    selected_id: {
      type: [Number, String],
    },
  },
  data() {
    return {};
  },
  methods: {
  },

  computed: {
    ...mapGetters(["is_provider", "getTypeValidation"]),
  },
};
</script>
