<template>
  <div>
    <Header />
    <div class="d-md-flex content-wrapper px-3">
      <context-menu ref="context">
        <skeleton height="30px" grid="1" :rows="5" v-if="modules && modules.length === 0" />
        <b-button block :variant="selectedModule.name === mod.name ? 'primary' : 'light'"
          v-for="(mod, index) in modules" :key="index" @click="handleSelectModule(mod)"
          class="d-flex align-items-center px-2 text-left justify-content-between" :disabled="!mod.enabled">
          <div class="d-flex gap-3 align-items-center">
            <b-img :src="getIcon(mod.icon)" :alt="mod.nam" height="25" />

            <strong v-if="mod && mod.name" class="line-height-1">
              {{ mod.name }}
            </strong>
          </div>
          <small>{{ mod.weight * 100 }}%</small>
        </b-button>
      </context-menu>
      <div class="indicators w-100">
        <div class="d-md-flex align-items-center justify-content-between">
          <div class="d-md-flex align-items-end">
            <section>
              <h5 class="mb-0" v-if="selectedModule && selectedModule.name">
                {{ selectedModule.name }}
              </h5>
              <span v-if="!is_mobile">
                O peso para este módulo é
                <strong>{{ selectedModule.weight * 100 }}%</strong>, clique em
                <strong>calibrar módulo</strong> para alterar
              </span>
            </section>
          </div>

          <div class="d-flex">
            <b-button variant="link" class="p-0" @click="handleCalibrateModule()">
              <b-icon icon="sliders" class="mr-2" />
              Calibrar módulo
            </b-button>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between mt-3 gap-2">
          <b-form-group label="Referência" class="w-100 w-md-50" v-if="selectedModule.children">
            <multiselect @select="handleSelectsubModule" v-model="selectedSubmodule" :close-on-select="true"
              :show-labels="false" :allow-empty="false" placeholder="Selecione uma referencia" label="name"
              :options="selectedModule.children" />
          </b-form-group>

          <b-button v-if="!is_provider" @click="handleAddTemplateIndicator()" class="btn-add">
            Adicionar indicador
          </b-button>
        </div>

        <div class="indicators-list w-100">
          <skeleton height="58px" grid="1" :rows="6" v-if="loading && emptyTemplates" />

          <empty-list v-if="emptyTemplates && !loading" text="Não há indicadores cadastrados" />

          <card-template v-for="(template, index) in templates" :key="index" :index="index" :selected_id="selectedId"
            @delete="handleDeleteTemplateIndicator(template.id)" @edit="handleEditTemplateIndicator(template.id)"
            :template="template" />
        </div>
      </div>
      <b-modal id="calibration-module" centered size="md" hide-footer no-close-on-backdrop no-close-on-esc
        header-class="d-md-flex d-block">
        <template #modal-header>
          <h5>Calibrar peso do módulos</h5>
          <span class="d-block">
            Esse peso é utilizado para a nota final da avaliação
          </span>
        </template>
        <div class="w-100">
          <div class="my-3">
            <div ref="sliderWeight"></div>
          </div>
          <ApexCharts type="donut" :options="chartOptionsCategories" :series="weights" />

          <div class="d-flex align-items-center justify-content-end w-100">
            <b-button @click="handleCloseCalibrateModule()" class="mt-2" variant="primary" size="sm">
              Fechar
            </b-button>
          </div>
        </div>
      </b-modal>
      <!-- New Template -->
      <b-modal id="add-new-template" centered size="xl" hide-footer no-close-on-backdrop no-close-on-esc>
        <template #modal-header>
          <h5>
            {{ isAdd ? "Adicionar" : "Editar" }} indicador de
            {{ selectedSubmodule.name }}
          </h5>
        </template>
        <card-new-template :is_add="isAdd" :selected_id="selectedId" :selected_submodule="selectedSubmodule"
          @close_modal_template="handleCancelRegister()" :selected_module="selectedModule" />
      </b-modal>
      <!-- /New Template -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ContextMenu from "@/components/ContextMenu";
import CardNewTemplate from "./CardNewTemplate";
import CardTemplate from "./CardTemplate";
import noUiSlider from "nouislider";
import ApexCharts from "vue-apexcharts";
import budget from "@/assets/images/budget.svg";
import note from "@/assets/images/note.svg";
import lawyer from "@/assets/images/lawyer.svg";
import technicalSupport from "@/assets/images/technical-support.svg";
import Header from "./Header.vue";

export default {
  components: {
    ContextMenu,
    CardNewTemplate,
    CardTemplate,
    ApexCharts,
    Header,
  },
  data() {
    return {
      selectedSubmodule: {
        children: [],
      },
      submodules: [
        {
          name: "Lançamento de Rendimentos",
          url: "balance-sheet",
          status: 1,
        },
        {
          name: "Indicadores Financeiros",
          url: "income",
          status: 1,
        },
      ],
      weights: [],
      chartOptionsCategories: {
        chart: {
          type: "pie",
        },

        labels: [],
        responsive: [
          {
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      template_id: null,
      selectedId: null,
      emptyTemplates: false,
      isAdd: false,
      loading: false,
      selectedModule: "",
      modules: [],
      templates: [],
      sourceOptions: [],
    };
  },
  computed: {
    ...mapGetters(["is_provider", "is_mobile"]),
  },
  methods: {
    ...mapActions([
      "get_template",
      "edit_template",
      "get_all_indicator",
      "get_all_template_indicator",
      "delete_template_indicator",
    ]),

    getIcon(icon) {
      const icons = {
        budget: budget,
        note: note,
        lawyer: lawyer,
        "technical-support": technicalSupport,
      };
      return icons[icon];
    },

    handleSelectModule(mdl) {
      this.selectedModule = mdl;
      this.selectedSubmodule = mdl.children[0];
      this.getTemplateIndicator();
      this.handleCancelRegister();
      this.$refs.context.close();
    },

    handleSelectsubModule(submodule) {
      this.selectedSubmodule = submodule;
      this.getTemplateIndicator();
    },

    getTemplateIndicator() {
      this.loading = true;
      const module_id = this.selectedModule.id;
      const sub_module = this.selectedSubmodule.code;
      this.get_all_template_indicator({
        module_id,
        sub_module,
        template_id: this.template_id,
      }).then(({ results, total }) => {
        this.templates = results;
        this.loading = false;

        this.emptyTemplates = total === 0;
      });
    },
    convertPointsToWeights(positions) {
      this.weights = [
        positions[0],
        positions[1] - positions[0],
        positions[2] - positions[1],
        10 - positions[2],
      ];
      this.$nextTick(() => {
        this.$forceUpdate();
      });
      this.handleEditTemplate();
    },
    convertWeightsToPoints(weights) {
      return [
        weights[0],
        weights[0] + weights[1],
        weights.reduce((a, b) => a + b, 0) - weights[3],
      ];
    },
    createdSlider() {
      const slider = this.$refs.sliderWeight;
      noUiSlider.create(slider, {
        start: this.convertWeightsToPoints(this.weights),
        step: 0.5,
        connect: [true, true, true, true],
        range: {
          min: [0],
          max: [10],
        },
      });
      const connect = slider.querySelectorAll(".noUi-connect");
      const classes = ["financial", "operational", "legal", "regularity"];

      for (let i = 0; i < connect.length; i++) {
        connect[i].classList.add(classes[i]);
      }

      slider.noUiSlider.on(
        "change.one",
        function (positions) {
          positions = positions.map((number) => Number(number));
          this.convertPointsToWeights(positions);
        }.bind(this)
      );
      this.$forceUpdate();
    },
    handleCloseCalibrateModule() {
      this.$bvModal.hide("calibration-module");
      this.getTemplate();
    },

    handleCalibrateModule() {
      this.$bvModal.show("calibration-module");
      this.$nextTick(() => {
        this.createdSlider();
      });
    },

    handleAddTemplateIndicator() {
      this.isAdd = true;
      this.$bvModal.show("add-new-template");
      this.emptyTemplates = this.templates?.length === 0;
    },

    handleCancelRegister() {
      this.$bvModal.hide("add-new-template");
      this.selectedId = null;
      this.getTemplateIndicator();
    },

    handleEditTemplateIndicator(id) {
      this.isAdd = false;
      this.selectedId = id;
      this.$bvModal.show("add-new-template");
    },

    handleDeleteTemplateIndicator(id) {
      this.selectedId = id;
      this.delete_template_indicator(id).then(() => {
        this.getTemplateIndicator();
        this.selectedId = null;
      });
    },
    handleEditTemplate() {
      // Ajusta os pesos antes de enviar
      this.weights = this.weights.map(weight => weight / 10);
      this.edit_template({ id: this.template_id, weights: this.weights }).then(
        () => {
          this.getTemplateIndicator();
        }
      );
    },

    getTemplate() {
      this.get_template({ id: this.template_id }).then(({ data }) => {
        this.modules = data.modules;
        this.chartOptionsCategories.labels = this.modules.flatMap(
          ({ name }) => [name]
        );
        this.weights = data.weights.map(weight => weight * 10);
        this.selectedModule = this.modules[0];
        if (this.selectedModule.children) {
          this.selectedSubmodule = this.selectedModule.children[0];
        }
        this.getTemplateIndicator();
      });
    },
  },

  mounted() {
    this.template_id = this.$route.params.id;
    this.getTemplate();
  },

  watch: {
    selectedModule: {
      handler(items) {
        if (items?.children) this.selectedSubmodule = items.children[0];
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.auto-track {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  z-index: 47;

  .form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 47;
  }

  .backdrop {
    opacity: 0 !important;
    background: $bg !important;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
