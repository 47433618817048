<template>
  <div class="bg-white py-md-2 py-2 position-relative mb-3">
    <div class="content-wrapper d-md-flex justify-content-between px-3">
      <div>
        <div class="d-flex align-items-center mb-2">
          <router-link class="pointer h5 m-0" :to="`/system/template-indicators/list`">
            <b-icon icon="arrow-left" class="mr-2" />
          </router-link>
          <skeleton v-if="!current_template" height="24px" :rows="1" grid="1" />
          <h5 class="m-0" v-else>{{ current_template.name }}</h5>
        </div>

        <p
          class="mt-2 w-100"
          v-if="current_template && current_template.description"
        >
          {{ current_template.description }}
        </p>
      </div>

      <div class="d-flex gap-2 actions">
        <b-button
          size="sm"
          variant="primary"
          class="text-nowrap"
          @click="handleCopyTemplate"
        >
          <b-icon-clipboard />
          Criar uma cópia
        </b-button>
        <b-button
          v-if="!current_template.is_default"
          variant="secondary"
          class="icon-action p-3"
          @click="handleEditTemplate()"
        >
          <b-icon-pen />
        </b-button>
        <b-button
          v-if="!current_template.is_default"
          variant="danger"
          class="icon-action p-3"
          @click="handleOpenDeleteTemplateConfirm()"
        >
          <b-icon-trash />
        </b-button>
      </div>
    </div>
    <b-modal
      id="copy-indicator-template"
      hide-footer
      hide-header
      centered
      size="md"
      no-close-on-backdrop
    >
      <section class="text-center mb-4">
        <h5>Estamos fazendo uma cópia deste template</h5>
      </section>
      <section>
        <cards-list grid="4" responsive="4">
          <section
            class="d-flex justify-content-center align-items-center flex-column text-center line-height-1 gap-2"
          >
            <img :src="budget" width="30" class="d-block" />
            <span>Financeiro</span>
            <b-icon-check-circle-fill
              :variant="copying >= 1 ? 'success' : 'light'"
              size="md"
            />
          </section>
          <section
            class="d-flex justify-content-center align-items-center flex-column text-center line-height-1 gap-2"
          >
            <img :src="technicalSupport" width="30" class="d-block" />
            <span>Técnico</span>
            <b-icon-check-circle-fill
              :variant="copying >= 2 ? 'success' : 'light'"
              size="md"
            />
          </section>
          <section
            class="d-flex justify-content-center align-items-center flex-column text-center line-height-1 gap-2"
          >
            <img :src="lawyer" width="30" class="d-block" />
            <span>Legal Trabalhista</span>
            <b-icon-check-circle-fill
              :variant="copying >= 3 ? 'success' : 'light'"
              size="md"
            />
          </section>
          <section
            class="d-flex justify-content-center align-items-center flex-column text-center line-height-1 gap-2"
          >
            <img :src="note" width="30" class="d-block" />
            <span>Regularidade</span>
            <b-icon-check-circle-fill
              :variant="copying >= 4 ? 'success' : 'light'"
              size="md"
            />
          </section>
        </cards-list>
        <h6 class="text-center mt-2">
          {{ copying === 5 ? "Completo!" : "Copiando..." }}
        </h6>
      </section>
    </b-modal>
    <b-modal
      id="add-indicator-template"
      hide-footer
      centered
      size="md"
      :title="`Editar ${!current_template ? '' : current_template.name}`"
      no-close-on-backdrop
    >
      <Form @submited="handleCloseModal" />
    </b-modal>
    <b-modal id="delete-template-indicator" centered size="md">
      <template #modal-header> <h5>Cuidado!</h5></template>
      <p v-if="current_template">
        Você apagará o template
        <strong> {{ current_template.name }} </strong>. <br />
        Essa ação não poderá ser desfeita, deseja continuar?
      </p>
      <template #modal-footer="{ close }">
        <b-button variant="link" size="sm" @click="handleDeleteTemplate()">
          Sim
        </b-button>
        <b-button variant="primary" size="sm" @click="close()">Não</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import Form from "./form.vue";
import budget from "@/assets/images/budget.svg";
import note from "@/assets/images/note.svg";
import lawyer from "@/assets/images/lawyer.svg";
import technicalSupport from "@/assets/images/technical-support.svg";

export default {
  components: {
    Form,
  },
  props: {
    back: {
      type: String,
    },
  },
  data() {
    return {
      budget,
      note,
      lawyer,
      technicalSupport,
      template_id: "",
      copying: 0,
      time: null,
    };
  },
  computed: {
    ...mapGetters(["is_provider", "is_mobile", "current_template"]),
  },
  methods: {
    ...mapActions([
      "hide_application_header",
      "get_template_indicator",
      "get_template",
      "show_delete",
      "delete_template",
      "copy_template",
    ]),
    getTemplate() {
      this.get_template({ id: this.template_id });
    },

    copyTemplate(data) {
      this.$router
        .replace({
          params: { id: data.id },
        })
        .then(() => {
          this.get_template({ id: data.id });
        });
    },

    handleCopyTemplate() {
      this.copy_template(this.template_id).then(({ data, status }) => {
        this.time = setInterval(() => {
          this.copying++;
          if (this.copying === 5) {
            clearInterval(this.time);
          }
        }, 500);

        _.delay(
          () => {
            this.template_id = data.id;
            if (status === 200) {
              this.copyTemplate(data);
              this.$bvModal.hide("copy-indicator-template");
              this.copying = 0;
              this.$router.go(`/system/template-indicators/${data.id}/modules`);
            }
          },
          3000,
          this.$bvModal.show("copy-indicator-template")
        );
      });
    },
    handleOpenDeleteTemplateConfirm() {
      this.template_id = this.$route.params.id;
      this.$bvModal.show("delete-template-indicator");
    },

    handleDeleteTemplate() {
      this.delete_template(this.template_id).then(() => {
        this.$router.push("/system/template-indicators/list");
        this.show_delete({
          in_delete: false,
        });
      });
    },

    handleEditTemplate() {
      this.template_id = this.$route.params.id;
      this.$bvModal.show("add-indicator-template");
    },

    handleCloseModal() {
      this.$bvModal.hide("add-indicator-template");
      this.getTemplate();
    },
  },
  beforeDestroy() {
    this.hide_application_header(true);
  },
  mounted() {
    this.hide_application_header(false);
    this.template_id = this.$route.params.id;
    this.getTemplate();
  },
};
</script>
